module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-next-seo/gatsby-browser.js'),
      options: {"plugins":[],"openGraph":{"type":"website","locale":"en_IE","url":"https://www.sebule.com/","site_name":"SEBULE Classifieds"},"twitter":{"handle":"@sebuleads","site":"@sebuleads","cardType":"summary_large_image"}},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"G-3WE3T28VW0","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"sebule-landing"}},"routeChangeEventName":"sebule-landing-route-change","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.sebule.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SEBULE Classifieds","short_name":"SEBULE","start_url":"/","background_color":"#ffffff","theme_color":"#66cc66","display":"standalone","icon":"static/favicons/favicon-512x.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"93666ac5614470cdc048836530c78910"},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://blog-api.sebule.com/graphql","schema":{"timeout":240000,"perPage":10,"requestConcurrency":50,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp","previewRequestConcurrency":5},"html":{"useGatsbyImage":true,"imageMaxWidth":600,"imageQuality":65,"fallbackImageMaxWidth":600,"createStaticFiles":true,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"debug":{"graphql":{"showQueryVarsOnError":true,"showQueryOnError":false,"copyQueryOnError":false,"panicOnError":false,"onlyReportCriticalErrors":true,"copyNodeSourcingQueryAndExit":false,"writeQueriesToDisk":false,"printIntrospectionDiff":false},"preview":false,"timeBuildSteps":false,"disableCompatibilityCheck":false,"throwRefetchErrors":false},"verbose":true,"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-sumo/gatsby-browser.js'),
      options: {"plugins":[],"sumoSiteId":"ffbf2f2e202167d9c12e154b73d38763a150b7de6cd79b805d303b1be902cc09"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
